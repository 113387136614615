import type { CartProduct, Currency } from '~~/types'
import { useBrowserLocale } from '../node_modules/@nuxtjs/i18n/dist/runtime/composables'
import {
  ref,
  computed,
  useNuxtApp,
  defineStore,
  useCookie,
  useRuntimeConfig,
  watch,
  useUserStore,
} from '#imports'

export const useCart = defineStore('crt', () => {
  const { $trpc } = useNuxtApp()
  const userStore = useUserStore()

  function encode(ids: string[]) {
    return btoa(JSON.stringify(ids))
  }
  function decode(v: string): string[] {
    return JSON.parse(atob(v))
  }

  // state
  const ids = ref<string[]>([])
  const idsCookie = useCookie<string>('divine-cart', { default: () => encode([]) })
  const products = ref<CartProduct[]>([])
  const maxItemsReached = ref(false)

  // currency
  const euroLocaleCountries = [
    'fr', // French
    'de', // German
    'es', // Spanish
    'it', // Italian
    'pt', // Portuguese
    'nl', // Dutch
    'el', // Greek
    'gr', // Greek
    'fi', // Finnish
    'et', // Estonian
    'lv', // Latvian
    'lt', // Lithuanian
    'mt', // Maltese
    'sk', // Slovak
    'sl', // Slovenian
    'ga', // Irish
    'hr', // Croatian
    'sv', // Swedish
    'se', // Swedish
    'da', // Danish
    'dk', // Danish
    'nb', // Norwegian / Norway
    'no', // Norwegian / Norway
    'pl', // Polish
    'cs', // Czech
    'cz', // Czech
    'hu', // Hungarian
    'bg', // Bulgarian
    'ro', // Romanian
  ]

  function getDefaultCurrency(): Currency {
    const browserLocale = useBrowserLocale()
    const found = euroLocaleCountries.find((e) => e === browserLocale?.toLowerCase())
    if (found) return 'eur'
    return 'usd'
  }

  const selectedCurrency = useCookie<Currency>('divine-currency', {
    default: getDefaultCurrency,
  })

  const currencies = [
    {
      value: 'usd',
      title: `USD $`,
      symbol: '$',
    },
    {
      value: 'eur',
      title: `EUR €`,
      symbol: '€',
    },
  ]

  const currency = computed(() => {
    // force usd as currency
    return currencies[0]
    /*
    const found = currencies.find((e) => e.value === selectedCurrency.value)
    if (found) return found
    return currencies[0]
    */
  })

  async function updateProducts() {
    const _products = []
    if (ids.value.length) {
      const data = await $trpc.cat_Instruments.getCartProductsByIds.query(ids.value)
      if (data) {
        for (const id of ids.value) {
          const found = data.find((p) => p._id === id)
          if (found) _products.push(found)
        }
      }
    }
    products.value = _products
  }

  // getters
  const count = computed(() => ids.value.length)
  const empty = computed(() => {
    if (ids.value.length === 0) return true
    else return false
  })
  const totalPrice = computed(() => {
    return products.value.reduce((acc, product) => acc + product.price, 0)
  })

  // setters
  async function addToCart(id: string) {
    const config = useRuntimeConfig()
    if (count.value >= config.public.stripe.maxCartItems) {
      maxItemsReached.value = true
      return
    }
    // if item already purchased then returns
    if (userStore.user?.purchases?.instrument_ids) {
      if (userStore.user.purchases.instrument_ids.find((p) => p.toString() === id)) return
    }
    if (ids.value.find((_id) => _id === id)) return
    if (!ids.value.find((_id) => _id === id)) {
      ids.value.push(id)
    }
  }

  async function removeFromCart(id: string) {
    ids.value = ids.value.filter((_id) => _id !== id)
  }

  function clean() {
    ids.value = []
  }

  if (!userStore.isAuthenticated) {
    ids.value = decode(idsCookie.value)
  }

  /**
   * Watchers
   * vue watchers : we need to use deep: true to watch ref / computed / reactive in Pinia store https://github.com/vuejs/pinia/discussions/2532
   */

  watch(
    ids,
    async (v) => {
      if (userStore.isAuthenticated) {
        await userStore.saveCart(v)
      }
      idsCookie.value = encode(v)
      await updateProducts()
    },
    { deep: true }, // deep is mandatory to work with arrays
  )

  watch(
    () => userStore.isAuthenticated,
    async (v) => {
      if (v === true) {
        if (idsCookie.value?.length)
          ids.value = await $trpc.sys_Users.getFilteredCartIds.query(decode(idsCookie.value))
        if (!ids.value.length && userStore.user?.cart?.instrument_ids)
          ids.value = await $trpc.sys_Users.getFilteredCartIds.query(
            userStore.user.cart.instrument_ids.map((e) => e.toString()),
          )
        if (ids.value.length && !userStore.user?.cart?.instrument_ids) userStore.saveCart(ids.value)
      }
    },
    { immediate: true, deep: true }, // deep is mandatory to work with arrays)
  )

  // API
  return {
    currencies,
    selectedCurrency,
    currency,
    products,
    count,
    empty,
    maxItemsReached,
    totalPrice,
    addToCart,
    removeFromCart,
    updateProducts,
    clean,
  }
})
